import React, { Fragment } from 'react';
import { Admin, Resource } from 'react-admin';
import { Route, Redirect } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import Dashboard from './resources/Dashboard';
import Layout from './resources/Layout';
import Login from './resources/Login'
import { loginService, authProvider, i18nProvider } from './providers';

export const cards_config = "/cards.json";

export const setApplication = () => {
	sessionStorage.removeItem("application");
};

export const redirectLogin = () => {
	setApplication();
	window.location.href = '/ui/login';
};

export const redirectHome = (props) => {
	const app = sessionStorage.getItem("application");
	setApplication();

	if (app) {
		window.location.href = window.location.origin + app;
	} else {
		props && props.history && props.history.push("/dashboard");
		return <Redirect to="/dashboard" />;
	}

	return <Fragment />;
};

export const profile = () => {
	window.location.href = '/ui/users/#/profile';
	return <Fragment />;
}

const theme = createMuiTheme({
	sidebar: {
        width: 0,
        closedWidth: 0
	},
	palette: {
		primary: {
			main: '#448ab6'
		},
		secondary: {
			main: '#448ab6'
		}
	}
});

const customRoutes = [
	<Route key='1' path="/login" noLayout render={() => <Login theme={theme} />} />,
	<Route key='2' path="/dashboard" render={() => loginService.getUser() ? <Dashboard theme={theme} /> : <Redirect to="/login" />} />,
	<Route key='3' path={"/users/profile"} render={profile} />,
];

const App = () => (
	<Admin
		theme={theme}
		i18nProvider={i18nProvider}
		authProvider={authProvider} 
		dataProvider={() => Promise.resolve()} // no dataProvider for this app
		layout={Layout}
		customRoutes={customRoutes}
		loginPage={() => <Login theme={theme} />}
	>
		{/* Resource must be mfa to ensure login routes to the mfa page when needed */}
		<Resource name="login/mfa" />
	</Admin>
);

export default App;
