import axios from 'axios';
import { setApplication } from '../App';
import { loginStore } from '../resources/Login/LoginStore';
import { authUrl } from './index';
import ActivityTimeout from './timeoutService';
import TokenManager from './tokenManager';

export default class LoginService {
	tokenManager: TokenManager;
	activityTimeout: ActivityTimeout;

	constructor(tokenManager: TokenManager) {
		this.tokenManager = tokenManager;
		this.activityTimeout = new ActivityTimeout(this, tokenManager);
	}

    login = ({username, password}: {username: string, password: string}) => {
		return new Promise((resolve, reject) => {
			this.tokenManager.login(username, password)
			.then((rsp) => {
				if (rsp && rsp.data) {
					if (rsp.data === "locked") {
						loginStore.setLocked();
					} else if (rsp.data.code) {
						loginStore.setReset(username, rsp.data.type, rsp.data.code);
					} else if (rsp.data.mfa) {
						loginStore.setMFA(username, rsp.device);
					}
				} else {
					loginStore.clear();
				}
				return resolve();
			})
			.catch((error) => {
				if (error.response) {
					const response = error.response;
					if (response.status === 401) {
						if (response.statusText === "LOCKED") {
							loginStore.setLocked();
							return resolve();
						}
						return reject(new Error('eiss.auth.invalid'));
					} else if (response && response.data) {
						return reject(new Error('eiss.auth.unable'));
					}
					return reject(new Error('eiss.auth.failed'));
				} else {
					return reject(new Error('eiss.no_response'));
				}
			});
		});
	};
    
	logout = () => {
		this.tokenManager.removeToken();
		this.activityTimeout.cancel();
		setApplication();
	};

	getUser = () => {
		const name = this.tokenManager.getUser();
		const group = this.tokenManager.getGroup();
		const role = this.tokenManager.getRole();
		if (name && group && role) {
			return {name, group, role};
		} else {
			return null;
		}
	};

	getToken = () => {
        return this.tokenManager.getToken();
    }

	checkMfa = async (mfatoken: string) => {
		return new Promise<void>((resolve, reject) => {
			const {username, device} = loginStore;
			const options = {
				headers: {'Content-Type': 'application/json'}
			}
			axios.post(
				authUrl + "/checkmfa",
				{ username, mfatoken, device },
				options)
			.then((resp) => this.tokenManager.processToken(resp.data, device as string))
			.catch((error) => reject(error))
			.then(() => {
				return resolve();
			});
		});
	};

}
