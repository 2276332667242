import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

const messages:any = {
    en: {
        ...englishMessages,
        eiss: {
            auth: {
                expired: "Your session is expired",
                invalid: "Invalid credentials",
                failed: "Login failed",
                unable: "Unable to login at this time",
                process: "Error processing token"
            },
            no_response: "No response from server",
            reset: {
                "network error": "Network Error",
            }
        }
    }
}

export const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'en');
