import React, {Fragment, useState, useEffect} from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import * as Icons from '@material-ui/icons';
import { Title } from 'react-admin';
import axios from 'axios';
import {cards_config} from '../App';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({ 
    grid: {
        flexGrow: 1,
        marginTop: theme.spacing(4)
    },
    card: {
        height: 180,
        width: 220,
        textAlign: 'center',
        padding: theme.spacing(2),
        color: theme.palette.secondary.contrastText,
		backgroundColor: theme.palette.primary.main
	},
    icon: {
        fontSize: 48,
        paddingBottom: theme.spacing(2)
    },
	text: {
        color: theme.palette.secondary.contrastText
	},
    link: {
        textDecoration: "none"
    },
	version: {
		textAlign: "right",
        color: blue[800]
	}
}));

const Dashboard = () => {
    const [cards, setCards] = useState([]);
    const classes = useStyles();

	useEffect(() => {
		axios.get(cards_config)
        .then(response => {
            return response.data;
        })
        .then((cards) => {
            if (Array.isArray(cards)) setCards(cards);
        })
        .catch(() => {});		
	}, []);

	return (
		<Fragment>
			<Title title='EISS™Home'/>
			<Grid className={classes.grid}
				container
				justify='center' 
				spacing={2}
			>
				{cards.map((card, i) => {
					let Icon = Icons[card.icon];
					return (
						<Grid item key={i}>
							<a className={classes.link} href={card.link} >
								<Card className={classes.card} >
									<Typography className={classes.version} variant='body2'>
										&nbsp;{card.version ? card.version : null}
									</Typography>
									<CardContent>
										<Icon className={classes.icon} />
										<Typography className={classes.text} gutterBottom variant='h5'>
											{card.text}
										</Typography>
									</CardContent>
								</Card>
							</a>
						</Grid>
					);
				})}
			</Grid>
		</Fragment>
    );
};

const DashboardWithTheme = (props) => (
    <ThemeProvider theme={props.theme}>
        <Dashboard {...props} />
    </ThemeProvider>
);

export default DashboardWithTheme;
