import axios from 'axios';
import { development, authUrl } from '../../providers';

const options = {
    headers: {'Content-Type': 'application/json'}
}

class PasswordResetService {
    code: string = "";

    sendPasswordResetEmail = (email: string) => {
        const url = window.location.protocol + "//" + window.location.host +
            (development ? "/#/login/reset/": "/ui/home/#/login/reset/");
        return axios.post(
            authUrl + "/reset/request", 
            {email, url}, 
            options);
    }

    resetPassword = async (password: string) => (
        axios.post(
            authUrl + "/reset/password",
            { code: this.code, password },
            options)
        .then(() => {
            this.code = "";
        })
    )
    
    validateCode = async (_code: string) => {
        this.code = _code;
        return axios.post(
            authUrl + "/reset/validate/code",
            {code: this.code},
            options);
    }
    
    validatePassword = async (password: string) => {
        return axios.post(
            authUrl + "/reset/validate/password", 
            {code: this.code, password}, 
            options);
    }
}

export const resetService = new PasswordResetService();
