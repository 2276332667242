import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { Form, Field } from 'react-final-form';
import { Card, CardActions, TextField } from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useTranslate, useNotify } from 'ra-core';
import { Button, Notification } from 'react-admin';
import { connect, useDispatch } from 'react-redux';
import { loginService } from '../../providers';
import Logo from './Logo';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        minWidth: 400,
        marginTop: theme.spacing(20),
        backgroundColor: lightBlue[50]
    },
    icon: {
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.secondary.light
    },
    form: {
        padding: theme.spacing(2)
    },
    input: {
        marginTop: theme.spacing(1)
    },
    actions: {
        padding: theme.spacing(2)
    },
    loginProgress: {
        marginRight: theme.spacing(2)
    },
    backBtn: {
        marginTop: theme.spacing(2)
    }
}));

const renderInput = ({
	meta: { touched, error } = {},
	input: { ...inputProps },
	...props
}) => (
	<TextField
		error={!!(touched && error)}
		helperText={touched && error}
		{...inputProps}
		{...props}
		fullWidth
	/>
);

const MFAForm = () => {
	const [loading, setLoading] = useState(false);
	const classes = useStyles();
	const dispatch = useDispatch();
	const translate = useTranslate();
	const notify = useNotify();

	const handleSubmit = (values) => {
		setLoading(true);

		return loginService.checkMfa(values.token)
		.then(() => {
			setLoading(false);
			dispatch(push("/dashboard"));
		})
		.catch((error) => {
			setLoading(false);
			if (error.response && error.response.data) {
				if (error.response.data === "NOT_ENABLED") {
					return dispatch(push("/login"));
				}
			}
			if (!error) {
				return notify('eiss.no_response', 'warning');
			} else {
				return notify('ra.auth.sign_in_error', 'warning');
			}
		});
	};

	const validate = (values) => {
		const errors = {};
		if (!values.token) {
			errors.token = translate('ra.validation.required');
		} else {
			const token = values.token;
			if (!(/^\d+$/.test(token)) || token.length < 6 || token.length > 6) {
				errors.token = 'Must be 6 digits';
			}
		}
		return errors;
	};

	return (
		<Form
			onSubmit={handleSubmit}
			validate={validate}
			render={({ handleSubmit, form }) => (
				<form onSubmit={handleSubmit} noValidate>
					<div className={classes.main}>
						<Card className={classes.card}>
							<Logo />
							<div className={classes.form}>
								<div className={classes.input}>
									<Field
										name='token'
										component={renderInput}
										label='Type a verification code from the Authenticator'
										disabled={loading}
										inputProps={{ autoComplete: 'off' }}
									/>
								</div>
							</div>
							<CardActions className={classes.actions}>
								<Button
									label='Authenticate'
									variant='contained'
									type='submit'
									disabled={loading}
									fullWidth
								>
									{loading ? (
										<CircularProgress
											className={classes.loginProgress}
											size={25}
											thickness={2}
										/>
									) : null}
								</Button>
							</CardActions>
						</Card>

						<Button 
							className={classes.backBtn}
							label='back to login'
							onClick={() => {
								form.reset();
								dispatch(push("/login"));
							}}
						/>

						<Notification />
					</div>
				</form>
			)}
		/>
	);
}

const MFAFormWithTheme = (props) => (
	<ThemeProvider theme={props.theme}>
		<MFAForm {...props} />
	</ThemeProvider>
);

const enhMFAFormWithTheme = connect(null, {
	push
})(MFAFormWithTheme);

export default enhMFAFormWithTheme;
