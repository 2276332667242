import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    logo: {
        background: 'url(/logo.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: theme.spacing(11),
        marginTop: theme.spacing(2)    }
});

const Logo = ({ classes }) => (
    <div className={classes.logo} />
);

export default withStyles(styles)(Logo);
