import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { redirectHome } from '../../App';
import { loginStore } from './LoginStore';

import LoginForm from './LoginForm';
import MFAForm from './MfaForm';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Locked from './Locked';
import { loginService } from '../../providers';

export default function Login (props) {
    const user = loginService.getUser();

    if (user && user.name !== "") { 
        return redirectHome();
    }

    if (!loginStore.passMfa()) {
        return (
            <Switch>
                <Route exact path="/login" render={() => <LoginForm {...props} />} />,
                <Route path="/login/mfa" render={() => <MFAForm {...props} />} />,
                <Route render={() => {
                    loginStore.clear();
                    return <Redirect to="/login" />} 
                } />
            </Switch>
        );
    }

    return (
        <Switch>
            <Route exact path="/login" noLayout render={() => <LoginForm {...props} />} />,
            <Route path="/login/locked" noLayout render={() => <Locked {...props} />} />,
            <Route path="/login/forgotpassword" noLayout render={() => <ForgotPassword {...props} />}  />,
            <Route path="/login/reset/:id" render={({ match }) => <ResetPassword type="reset" match={match} {...props} />}  />
            <Route path="/login/expire/:id" render={({ match }) => <ResetPassword type="expire" match={match} {...props} />}  />
            <Route path="/login/new/:id" render={({ match }) => <ResetPassword type="new" match={match} {...props} />} />
            
            <Route render={() => {
                const store = loginStore.toObject();
                if (store.type === "locked") {
                    return <Redirect to={`/login/locked`} />;
                }
                else if (store.type === "expire") {
                    return <Redirect to={`/login/expire/${store.code}`} />;
                }
                else if (store.type === "new") {
                    return <Redirect to={`/login/new/${store.code}`} />;
                }
                return <Redirect to="/login" />} 
            } />
        </Switch>
    );
}
