import React from 'react';
import { push } from 'connected-react-router';
import { Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { lightBlue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Button } from 'react-admin';
import { connect, useDispatch } from 'react-redux';
import Logo from './Logo';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        minWidth: 400,
        marginTop: theme.spacing(20),
        backgroundColor: lightBlue[50]
    },
    icon: {
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.secondary.light
    },
    form: {
        padding: theme.spacing(2)
    },
    input: {
        marginTop: theme.spacing(1)
    },
    actions: {
        padding: theme.spacing(2)
    },
    loginProgress: {
        marginRight: theme.spacing(2)
    },
    backBtn: {
        marginTop: theme.spacing(2)
    }
}));

const Locked = () =>  {
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<div className={classes.main}>
			<Card className={classes.card}>
				<Logo />
				<div className={classes.form}>
					<Typography varient='body2' >
                        Your account is locked!
                        <br/>
                        <br/>
                        Please contact an administrator
                        <br/>
                        to unlock your account.
					</Typography>
				</div>
			</Card>

			<Button 
				className={classes.backBtn}
				label='back to login'
				onClick={() => {
					dispatch(push("/login"));
				}}
			/>
		</div>
	);
}

const LockedWithTheme = (props) => (
	<ThemeProvider theme={props.theme}>
		<Locked {...props} />
	</ThemeProvider>
);

const enhLockedWithTheme = connect(null, {
	push
})(LockedWithTheme);

export default enhLockedWithTheme;
