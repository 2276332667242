type type = "" | "mfa" | "reset" | "new" | "expire" | "locked" | "fail";

class LoginStore {
    type: type;
    username: string | null;
    device: string | null;
    code: string | null;

    constructor() {
        this.clear();
    }

    setLocked() {
        this.type = "locked";
        this.username = null;
        this.device = null;
        this.code = null;
    }

    setMFA(username: string, device: string) {
        this.type = "mfa";
        this.username = username;
        this.device = device;
        this.code = null;
    }

    setReset(username: string, type: type, code: string) {
        this.type = type;
        this.username = username;
        this.device = null;
        this.code = code;
    }

    clear() {
        this.type = "";
        this.username = null;
        this.device = null;
        this.code = null;
    }

    toObject() {
        return ({
            type: this.type,
            username: this.username, 
            device: this.device,
            code: this.code
        });
    }

    passMfa() {
        return this.type !== "mfa";
    }
}

export const loginStore = new LoginStore();
