import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { lightBlue } from '@material-ui/core/colors';
import { Notification, Button } from 'react-admin';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import Logo from './Logo';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        minWidth: 470,
        marginTop: theme.spacing(20),
        backgroundColor: lightBlue[50]
    },
    icon: {
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.secondary.light
    },
    form: {
        padding: theme.spacing(2)
    },
    input: {
        marginTop: theme.spacing(1)
    },
    actions: {
        padding: theme.spacing(2)
    },
    loginProgress: {
        marginRight: theme.spacing(2)
    },
    resetBtn: {
        marginTop: theme.spacing(2)
    }
}));

const renderInput = ({
    meta: { touched, error } = { },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginForm = ({ location }) => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const dispatch = useDispatch();

    const handleSubmit = (auth) => {
        setLoading(true);
        login(auth, location && location.state ? location.state.nextPathname : '/')
            .catch((error) => {
console.log(error);

                setLoading(false);
                notify('ra.auth.sign_in_error', 'warning');
            }
        );
    };
    
    const validate = (values) => {
        const errors = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={ ({ handleSubmit, form }) => (
                <div className={classes.main}>
                    <form onSubmit={handleSubmit} noValidate>
                        <Card className={classes.card}>
                            <Logo />
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        name='username'
                                        component={renderInput}
                                        label={translate('ra.auth.username')}
                                        disabled={loading}
                                        inputProps={{ autoComplete: 'off' }}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        name='password'
                                        component={renderInput}
                                        label={translate('ra.auth.password')}
                                        type='password'
                                        disabled={loading}
                                        inputProps={{ autoComplete: 'off' }}
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    label={translate('ra.auth.sign_in')}
                                    variant='contained'
                                    type='submit'
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading ? (
                                        <CircularProgress
                                            className={classes.loginProgress}
                                            size={25}
                                            thickness={2}
                                        />
                                    ) : null}
                                </Button>
                            </CardActions>
                        </Card>

                    </form>

                    <Button 
                        className={classes.resetBtn}
                        label='forgot password?'
                        onClick={() => {
                            form.reset();
                            dispatch(push("/login/forgotpassword"));
                        }}
                    />

                    <Notification />
                </div>
            )}
        />
    );
};

const LoginWithTheme = (props) => (
    <ThemeProvider theme={props.theme}>
        <LoginForm {...props} />
    </ThemeProvider>
);

const enhLoginWithTheme = connect(null, {
    push
})(LoginWithTheme);

export default enhLoginWithTheme;
