import React from 'react';
import {AppBar, MenuItemLink} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {Person} from '@material-ui/icons';
import UserMenu from './UserMenu';

const styles = theme => ({
    title: {
        color: theme.palette.common.white,
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
		overflow: 'hidden',
		marginLeft: theme.spacing(4)
	},
	menuButton: {
		display: 'none'
	}
});

const EissUserMenu = props => {
    return (
        <UserMenu {...props}>
            <MenuItemLink 
                to={'/users/profile'}
                primaryText="Profile" 
                leftIcon={<Person />}
                onClick={props.onMenuClick} />
        </UserMenu>
    );
}

const EissAppBar = ({classes, ...props}) => {
    return (
        <AppBar classes={classes}
            userMenu={ <EissUserMenu /> }
            {...props}
        >
			<Typography className={classes.title} variant="h6" id="react-admin-title" />
        </AppBar>
    );
};

export default withStyles(styles)(EissAppBar);
