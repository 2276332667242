import React, {useState, useEffect} from 'react';
import { push } from 'connected-react-router';
import { Form, Field } from 'react-final-form';
import { Card, CardActions } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { lightBlue } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useNotify, useTranslate } from 'ra-core';
import { Button, PasswordInput } from 'react-admin';
import { connect, useDispatch } from 'react-redux';
import { resetService } from './resetService';
import processError from '../../providers/processError';
import Logo from './Logo';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        minWidth: 470,
        marginTop: theme.spacing(20),
        backgroundColor: lightBlue[50]
    },
    icon: {
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.secondary.light
    },
    form: {
        padding: theme.spacing(2)
    },
    input: {
        marginTop: theme.spacing(1)
    },
    actions: {
        padding: theme.spacing(2)
    },
    loginProgress: {
        marginRight: theme.spacing(2)
    },
    backBtn: {
        marginTop: theme.spacing(2)
    }
}));

const ResetPassword = ({ type, match }) =>  {
	const [reason, setReason] = useState('');
	const [reset, setReset] = useState(false);
	const notify = useNotify();

	useEffect(() => {
		resetService.validateCode(match.params.id)
		.catch(error => {
			if (!error.response) {
				notify('eiss.no_response', 'warning');
				return;
			} 

			if (error.response.data === 'EXPIRED_CODE') {
				setReason('expired');
			} else if (error.response.data === 'INVALID_CODE') {
				setReason('invalid');
			}
		});
	}, [match, notify]);

	return (
		<div>
			{
				(reason !== '') ?
					<InvalidCode reason={reason} /> 
				:
				(reset) ? 
					<ResetSuccess />
				: 
				<ResetForm setReset={setReset} type={type}/>
			}
		</div>
	);
}

const renderInput = ({
	meta: { touched, error } = {},
	input: { ...inputProps },
	...props
}) => (
	<PasswordInput
		error={!!(touched && error)}
		helperText={touched && error}
		{...inputProps}
		{...props}
		fullWidth
	/>
);

const ResetForm = ({ setReset, type }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const notify = useNotify();
	const translate = useTranslate();
	const dispatch = useDispatch();

	const handleSubmit = (values) => {
		setLoading(true);

		return resetService.resetPassword(values.password)
		.then(() => {
			setLoading(false);
			setReset(true);
		})
		.catch((error) => {
			setLoading(false);
			processError(error, "eiss.reset").catch(err => {
				notify(err.message, "warning");
			});
		})
	};

	const isOldPasswords = async values => {
		return resetService.validatePassword(values.password)
		.then(() => {
			return {};
		})
		.catch(() => {
			return { password: 'Old password already used' }
		})
	}

	const validate = (values) => {
		const errors = {};

		if (!values.password) {
			errors.password = translate('ra.validation.required');
		} else {
			const password = values.password;
			const min = 8;
			const max = 40;
			const digit = "(?=.*\\d)";
			const lower = "(?=.*[a-z])";
			const upper = "(?=.*[A-Z])";
			const special = "(?=.*[@#$%!])";
		
			const match = new RegExp(`(${digit}${upper}${lower}${special}.{${min},${max}})`);
	
			if (!match.test(password)) {
				if (password.length < 8) {
					errors.password = 'Must be more than 8 characters long';
				}
				else if (password.length >= 40) {
					errors.password = 'Must be less than 40 characters long';
				}
				else if (!(new RegExp(digit)).test(password)){
					errors.password = 'Must contain at least one digit';
				}
				else if (!(new RegExp(upper)).test(password)) {
					errors.password = 'Must contain at least one upper case character';
				}
				else if (!(new RegExp(lower)).test(password)) {
					errors.password = 'Must contain at least one lower case character';
				}
				else if (!(new RegExp(special)).test(password)) {
					errors.password = 'Must contain at least one special character from [ @ # $ % ! . ]';
				}    
			} 
		}

		return Object.keys(errors).length ? errors : isOldPasswords(values);
	};

	return (
		<Form
			onSubmit={handleSubmit}
			validate={validate}
			render={({ handleSubmit, form }) => (
				<form onSubmit={handleSubmit} noValidate>
					<div className={classes.main}>
						<Card className={classes.card}>
							
							<Logo />
							
							<div className={classes.form}>
								{(type === 'new') && 
									<Typography varient='body2' >
										Set the password for your new account
									</Typography>
								}
								{(type === 'reset') && 
									<Typography varient='body2' >
										Reset the password for your account
									</Typography>
								}
								{(type === 'expire') &&
									<Typography varient='body2' >
										Your current password has expired<br/>
										Please set a new password
									</Typography>
								}

								<div className={classes.input}>
									<Field
										name='password'
										component={renderInput}
										variant='standard'
										label='Password'
										disabled={loading}
										inputProps={{ autoComplete: 'off' }}
									/>
								</div>
							</div>
							<CardActions className={classes.form}>
								<Button
									label='Set password'
									variant='contained'
									type='submit'
									disabled={loading}
									fullWidth
								>
									{loading ? (
										<CircularProgress
											className={classes.loginProgress}
											size={25}
											thickness={2}
										/>
									) : null}
								</Button>
							</CardActions>
						</Card>

						<Button 
							className={classes.backBtn}
							label='back to login'
							onClick={() => {
								form.reset();
								dispatch(push("/login"));
							}}
						/>
					</div>
				</form>
			)}
		/>
	);
}

const ResetSuccess = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<div className={classes.main}>
			<Card className={classes.card}>
				<Logo />
				<div className={classes.form}>
					<Typography varient='body2' >
						Password has successfully been reset
					</Typography>
				</div>
			</Card>

			<Button 
				className={classes.backBtn}
				label='back to login'
				onClick={() => {
					dispatch(push("/login"));
				}}
			/>
		</div>
	);
};
  
const InvalidCode = ({ reason }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<div className={classes.main}>
			<Card className={classes.card}>
				<Logo />
				<div className={classes.form}>
					{reason === 'expired' &&
						<Typography varient='body2' >
							This link has expired!
							<br/>
							Please request a new recovery message.
						</Typography>
					}
					{reason === 'invalid' &&
						<Typography varient='body2' >
							This is not a valid link!
							<br/>
							Please check your email message.
						</Typography>
					}
				</div>
			</Card>

			<Button 
				className={classes.backBtn}
				label='back to login'
				onClick={() => {
					dispatch(push("/login"));
				}}
			/>
		</div>
	);
};

const ResetPasswordWithTheme = (props) => (
	<ThemeProvider theme={props.theme}>
		<ResetPassword {...props} />
	</ThemeProvider>
);

const enhResetPasswordWithTheme = connect(null, {
	push
})(ResetPasswordWithTheme);

export default enhResetPasswordWithTheme;
